/*
 * @Author: 云逸 1832583130@qq.com
 * @Date: 2023-05-03 09:50:44
 * @LastEditors: 云逸 1832583130@qq.com
 * @LastEditTime: 2024-05-06 12:04:15
 * @FilePath: \tea-admin\src\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import NProgress from 'nprogress'

/* 创建axios的一个实例 */
var instance = axios.create({
  baseURL: 'https://api.chajiuqu.com',
  // 配置跨域
  // baseURL: '/http',
  // 请求超时毫秒数
  timeout: 80 * 1000,
  method: 'post'
})

/* 请求拦截器 */
instance.interceptors.request.use(
  (config) => {
    // 进度条开启
    NProgress.start()

    // 登陆后请求携带 token
    const token = store.state.user.token
    if (token) {
      config.headers.token = token
    }

    return config
  },
  (error) => {
    // 抛出服务器响应错误
    return Promise.reject(error)
  }
)

/* 响应拦截器 */
instance.interceptors.response.use(
  (response) => {
    // 进度条关闭
    NProgress.done()

    const res = response.data
    // console.log('请求结果', res)

    // 判断服务器返回的数据
    if (!res.success && res.msg !== '获取成功') {
      if (res.msg === '删除成功') {
        return response.data
      }

      Message({
        message: res.msg,
        type: 'error',
        duration: 3 * 1000
      })

      // 未登录/没有权限
      if (res.msg === '登录超时' || res.msg === '请登录') {
        MessageBox.alert(res.msg, '提示', {
          showClose: false,
          showCancelButton: false
        }).then(() => {
          // 登出
          store.dispatch('user/logout')
          location.reload()
        })
      }

      return Promise.reject(res.msg)
    } else {
      return response.data
    }
  },
  (error) => {
    // 进度条关闭
    NProgress.done()

    // 抛出服务器响应错误
    Message({
      message: error,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default instance
